<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import rejectionModal from "../../common/modals/rejectionModal";
import approvalModal from "./components/approveRequestModal";

/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Prise en charge Visiteur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, rejectionModal, approvalModal },
  data() {
    return {
      // title: "Project Overview",
      uuid: "",
      items: [
        {
          text: "Requêtes",
          href: "/",
        },
        {
          text: "Prise en charge visiteur",
          active: true,
        },
      ],
      campsList: [],

      visitorSupportData: {
        id: 0,
        ref: "",
        date: "",
        dateIn: "",
        dateOut: "",
        needHosting: "",
        needRest: "",
        needTransport: "",
        location_type: "",
        structure_type: "",
        structure: "",
        externalDestination:"",
        externalDestinationTxt:"",
        reason: "",
        analyticAccount: "",
        companyName: "",
        file: "",
        notes: "",
        status: "",
        statusPlain: "",
        maintenanceOrderRef: "",
        maintenanceOrder: [],
        modelType: "",
        rejectedBy: "",
        approvedBy: "",
        rejectionReason: "",
        rejectionDate: "",
        prestations: "",
        approvedAt: "",
        reception: [],
        entries : [],
      },
    };
  },

  mounted() {
    this.uuid = this.$route.params.uid;
    this.fetchSingleTakeawayRequestData();
  },

  methods: {

    fetchSingleTakeawayRequestData() {
      var _this = this;
      this.$http
        .post("/ops/requests/visitors_support/getSingleRequestData/" + this.uuid)

        .then((res) => {
          var status = res.data.original.status;
          var tamr = res.data.original.data.tamr;
          switch (status) {
            case 200:
              _this.visitorSupportData.id = tamr.id;
              _this.visitorSupportData.ref = tamr.ref;
              _this.visitorSupportData.description = tamr.description;
              _this.visitorSupportData.requestedBy = tamr.requestedBy;
              _this.visitorSupportData.requestDate = tamr.created_at;
              _this.visitorSupportData.needHosting = tamr.need_hosting;
              _this.visitorSupportData.needRest = tamr.need_restauration;
              _this.visitorSupportData.needTransport = tamr.need_transport;
              _this.visitorSupportData.requestType = tamr.type;
              _this.visitorSupportData.requestLocationType = tamr.location;
              _this.visitorSupportData.requestLocation = tamr.location_type;
              _this.visitorSupportData.location_txt = tamr.location_txt;
              _this.visitorSupportData.requestUrgency = tamr.urgency;
              _this.visitorSupportData.status = tamr.status;
              _this.visitorSupportData.statusPlain = tamr.statusPlain;
              _this.visitorSupportData.maintenanceOrder = tamr.maintenanceOrder;
              _this.visitorSupportData.modelType = tamr.modelType;
              _this.visitorSupportData.rejectedBy = tamr.rejectedBy;
              _this.visitorSupportData.rejectionReason = tamr.rejectionReason;
              _this.visitorSupportData.rejectionDate = tamr.rejectionDate;
              _this.visitorSupportData.approvedBy = tamr.approvedBy;
              _this.visitorSupportData.approvedAt = tamr.approvedAt;
              _this.visitorSupportData.entries = tamr.entries;
              _this.visitorSupportData.reception = tamr.reception;
              _this.visitorSupportData.companyName = tamr.companyName;
              _this.visitorSupportData.analyic_account = tamr.analyic_account;
              _this.visitorSupportData.reason = tamr.reason;
              _this.visitorSupportData.prestations = tamr.prestations;
              _this.visitorSupportData.structure_type = tamr.structureType;
              _this.visitorSupportData.structure = tamr.structure;
              _this.campsList = tamr.campsList;

              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="visitorSupportData.ref" :items="items" />
    <div class="row" v-if="visitorSupportData.statusPlain == 'draft'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items" v-if="visitorSupportData.statusPlain == 'draft' && $can('edit_vsr')">
              <b-button
                variant="primary"
                v-if="visitorSupportData.statusPlain == 'draft'"
              >
                <i class="bx bx-edit font-size-16 align-middle mr-2"></i>
                Modifier
              </b-button>
              <b-button
                variant="success"
                v-b-modal.approvalModal
                v-if="visitorSupportData.statusPlain == 'draft' && $can('approve_vsr')"
              >
                <i
                  class="bx bx-check-double font-size-16 align-middle mr-2"
                ></i>
                Approuver
              </b-button>

              <b-button
                variant="danger"
                v-b-modal.rejectionModal
                v-if="visitorSupportData.statusPlain == 'draft' && $can('reject_vsr')"
              >
                <i class="bx bx-block font-size-16 align-middle mr-2"></i>
                Rejeter
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <a
              @click="fetchSingleTakeawayRequestData()"
              href="javascript:void()"
              ><h1><i class="bx bx-rotate-right" style="float: right;"></i></h1
            ></a>
            <h4 class="card-title mb-4">Détails</h4>

            <p lass="text-muted mb-4">
              {{ visitorSupportData.description }}
            </p>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Demandeur :</th>
                        <td>{{ visitorSupportData.requestedBy }}</td>
                      </tr>
                      <tr>
                        <th>Structure d'accueil :</th>
                        <td>
                          {{ visitorSupportData.structure_type}} : {{ visitorSupportData.structure }}
                        </td>
                      </tr>
                      <tr>
                        <th>Imputation :</th>
                        <td>
                          <b>{{ visitorSupportData.analyic_account }}</b>
                        </td>
                      </tr>
                      <tr>
                        <th>Motif de la demande :</th>
                        <td>
                          {{ visitorSupportData.reason }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Prestations :</th>
                        <td>
                          <span v-html="visitorSupportData.prestations"></span>
                        </td>
                      </tr>
                      <tr>
                        <th>Justificatif :</th>
                        <td>#</td>
                      </tr>
                      <tr>
                        <th>Date de demande :</th>
                        <td>{{ visitorSupportData.requestDate }}</td>
                      </tr>
                      <tr>
                        <th>Etat :</th>
                        <td>
                          <span v-html="visitorSupportData.status"></span>
                        </td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'rejected'">
                        <th>Rejeté par :</th>
                        <td>{{ visitorSupportData.rejectedBy }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'rejected'">
                        <th>Date de rejet:</th>
                        <td>{{ visitorSupportData.rejectionDate }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'rejected'">
                        <th>Raison de rejet:</th>
                        <td>{{ visitorSupportData.rejectionReason }}</td>
                      </tr>

                      <tr v-if="visitorSupportData.statusPlain == 'approved'">
                        <th>Approuvée par :</th>
                        <td>{{ visitorSupportData.approvedBy }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'approved'">
                        <th>Approuvée Le :</th>
                        <td>{{ visitorSupportData.approvedAt }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'approved'">
                        <th>Bon de Livraison :</th>
                        <td>
                          
                            <router-link
                          :to="{
                            name: 'ops.receptions.display',
                            params: { uid: visitorSupportData.reception.uuid },
                          }"
                          >{{ visitorSupportData.reception.ref }}</router-link>
                          </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-12">
                  <h4 class="card-title">Visiteurs: </h4> <br>
                  <h5>Entreprise: {{ visitorSupportData.companyName }}</h5>
                  <p class="card-title-desc"></p>

                  <div class="table-responsive">
                    <table class="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Nom complet</th>
                          <th>N° Pièce d'identité</th>
                          <th>Type de prise en charge</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="entry in visitorSupportData.entries" v-bind:key="entry.id">
                          <td>{{ entry.fullName }}</td>
                          <td>{{ entry.identityDocYype }} N°: <b>{{ entry.identityDocNo }}</b> </td>
                          <td>{{ entry.supportType.name }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <approval-modal :camps="campsList" :requestData="visitorSupportData" :modelUid="visitorSupportData.id" :modelRef="visitorSupportData.ref" />
    <rejection-modal
      :modelRef="visitorSupportData.ref"
      :modelId="visitorSupportData.id"
      :modelType="visitorSupportData.modelType"
    />
  </Layout>
</template>
